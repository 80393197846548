import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { WorkComponent } from './components/work/work.component';
import { ContactComponent } from './components/contact/contact.component';
import { LanguageBannerComponent, FooterComponent, BurgerMenuComponent } from "@shared/components";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HomeComponent, WorkComponent, ContactComponent, LanguageBannerComponent, FooterComponent, BurgerMenuComponent],
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'torostudio';
}
